import { cloneElement, useRef } from 'react';

import { CSSTransition } from 'react-transition-group';

export default function Slide({
  children,
  direction = 'right',
  ...props
}: React.ComponentProps<typeof CSSTransition> & {
  children: React.ReactElement;
  direction: 'right' | 'left' | 'modal' | 'bottom-right';
}) {
  const ref = useRef(null);
  return (
    <CSSTransition appear classNames={`Slide--${direction}`} mountOnEnter unmountOnExit {...props} nodeRef={ref}>
      {cloneElement(children, { ref, ...children.props })}
    </CSSTransition>
  );
}
