import { cloneElement, useRef } from 'react';

import { CSSTransition } from 'react-transition-group';

const focusOnFlyoutOpen = () => {
  const flyoutParent = document.getElementById('FlyoutParent');
  if (flyoutParent) {
    const firstFocus = document.getElementById('FocusElement');
    if (firstFocus) {
      firstFocus.focus();
    } else {
      flyoutParent.focus();
    }
  } else {
    const popoverContainer = document.getElementsByClassName('Popover__container')[0];
    if (popoverContainer) {
      const closeButton = popoverContainer.querySelector('.CloseBtn');
      if (closeButton) {
        closeButton.focus();
      } else {
        popoverContainer.focus();
      }
    }
  }
};
export default function FadeIn({
  children,
  ...props
}: { children: React.ReactElement } & React.ComponentProps<typeof CSSTransition>) {
  const ref = useRef(null);

  return (
    <CSSTransition classNames="fadeIn" onEntered={focusOnFlyoutOpen} timeout={300} {...props} nodeRef={ref}>
      {cloneElement(children, { ref, ...children.props })}
    </CSSTransition>
  );
}
